html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F1F1F1;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div span {
    position: absolute;
    bottom: -50px;
    border-radius: 50%;

    pointer-events: none;
    /*box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);*/
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        transform: translateY(-1200%);
        opacity: 0;
    }
}